import * as React from "react";
import { useMemo } from "react";
import Seo from "components/seo";
import Interview from "../../organisms/references/interview";
import { REFERENCES_INTERVIEW_DETAIL, REFERENCE_THUMB_INFO } from "../../constants/data/references";
import { OrganizeInterviewData } from "../../organisms/references/interview/organizeInterviewData";
import LayoutBasic from "../../templates/layoutBasic";
import { REFERENCES_META, REFERENCES_META_EN } from "../../constants/data/meta_data";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

const InterviewPage = ({ location }) => {
    const id = useMemo(() => {
        if (typeof location.state?.id === `undefined`) return "ace";

        return location.state.id;
    }, [location.state]);

    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return REFERENCES_META_EN;

        return REFERENCES_META;
    }, [i18n.language]);

    return (
        <LayoutBasic>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Interview interview={OrganizeInterviewData(id, REFERENCES_INTERVIEW_DETAIL)} thumb={OrganizeInterviewData(id, REFERENCE_THUMB_INFO)} />
        </LayoutBasic>
    );
};

export default InterviewPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
