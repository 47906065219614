import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Box } from "components/style/layoutStyle";
import { TextNormal, TextBold } from "components/style/textStyle";
import { Divider, PrevButtonText } from "./style";
import Image from "components/image/image";
import CustomLink from "components/link/customLink";
import { IC_PREV_ARROW_BK } from "../../../constants/media/icon";
import { useTranslation } from "react-i18next";

const InterViewDesc = ({ data }) => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Box maxWidth={"904px"} alignitems={"flex-start"} flexDirection={"column"} padding={isMobile !== "true" && "0 30px 0 0"}>
            <TextBold
                whiteSpace={isMobile === "true" ? "normal" : "pre-line"}
                margin={isMobile === "true" ? "0 0 40px" : "0 0 50px"}
                letterSpacing={isMobile === "true" && "-0.72px"}
                type={isMobile === "true" ? "24" : "34"}
                style={{ wordBreak: "keep-all" }}
            >
                {isMobile === "true" && t(data.title_mobile) ? t(data.title_mobile) : t(data.title)}
            </TextBold>
            <Divider />
            <TextNormal type={isMobile === "true" ? "16" : "18"} margin={isMobile === "true" ? "40px 0 50px" : "60px 0 80px"}>
                {t(data.desc)}
            </TextNormal>
            {data.qna.map((item, index) => {
                return (
                    <Box key={index} gap={"30px"} margin={isMobile === "true" ? "0 0 50px" : "0 0 80px"} alignitems={"flex-start"} flexDirection={"column"}>
                        <TextBold type={isMobile === "true" ? "20" : "24"} margin={"0"}>
                            {t(item.q)}
                        </TextBold>
                        <TextNormal type={isMobile === "true" ? "16" : "18"} margin={"0"} style={isMobile === "true" ? { wordBreak: "keep-all" } : {}}>
                            {isMobile === "true" ? t(item.a_mobile) : t(item.a)}
                        </TextNormal>
                    </Box>
                );
            })}
            <Divider />
            <Box gap={"2px"} justifycontent={"flex-start"} margin={isMobile === "true" ? "24px 0 60px" : "30px 0 80px"}>
                <CustomLink to={i18n.language === "ko" ? "/references" : `/${i18n.language}/references`}>
                    <div style={{ display: "flex", margin: "0 0 0 -8px" }}>
                        <Image filename={IC_PREV_ARROW_BK} alt={""} style={{ width: "24px", height: "24px" }} />
                        <PrevButtonText fontSize={isMobile === "true" ? "18" : "20px"}>{t("INTERVIEW_BACK_ICON_TITLE")}</PrevButtonText>
                    </div>
                </CustomLink>
            </Box>
        </Box>
    );
};

export default InterViewDesc;
