import * as React from "react";
import PropTypes from "prop-types";
import Header from "../organisms/header";
import Footer from "../organisms/footer";
import LocaleBase from "./LocaleBase";

const LayoutBasic = ({ children, bgColor }) => {
    return (
        <LocaleBase>
            <Header />
            <div style={{ margin: `0 auto` }}>
                <main>{children}</main>
            </div>
            <Footer />
        </LocaleBase>
    );
};

LayoutBasic.propTypes = {
    children: PropTypes.node.isRequired
};

export default LayoutBasic;
