import React from "react";
import { Box } from "components/style/layoutStyle";
import { TextNormal, TextBold } from "components/style/textStyle";
import { OtherCardCol, CardContainer, OtherCardRow, OtherCardTable, ContentWrapper } from "../style";
import Image from "components/image/image";
import { navigate } from "gatsby-link";
import { IC_ARROW_BK } from "../../../constants/media/icon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const OtherInterviewCard = ({ data }) => {
    const { t } = useTranslation();
    const moveToOtherInterview = (id) => {
        navigate("/references/interview", { state: { id: id } });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, [data]);

    return (
        <Box alignitems={"flex-start"} flexDirection={"column"}>
            <TextBold margin={"0 20px 24px"} type={"22"}>
                {t("INTERVIEW_OTHER_TITLE")}
            </TextBold>
            <OtherCardTable>
                <OtherCardRow xs={"3"}>
                    {data.map((item, index) => {
                        return (
                            <OtherCardCol key={index} onClick={() => moveToOtherInterview(item.id)}>
                                <CardContainer bgColor={"#f8f8f8"}>
                                    <Image filename={item.image} alt={t(item.company)} style={{ width: "280px", height: "180px", borderRadius: "8px 8px 0 0" }} />
                                    <ContentWrapper>
                                        <TextNormal padding={"0 0 2px"} type={"16"}>
                                            {t("INTERVIEW_OTHER_SUB_TITLE")}
                                        </TextNormal>
                                        <TextBold type={"22"}>{t(item.company)}</TextBold>
                                        <Image filename={IC_ARROW_BK} alt="arrow" style={{ width: "48px", height: "48px", position: "absolute", right: "30px" }} />
                                    </ContentWrapper>
                                </CardContainer>
                            </OtherCardCol>
                        );
                    })}
                </OtherCardRow>
            </OtherCardTable>
        </Box>
    );
};

export default OtherInterviewCard;
