import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Box, Wrapper } from "components/style/layoutStyle";
import { TextNormal, TextBold } from "components/style/textStyle";
import Image from "components/image/image";
import { useTranslation } from "react-i18next";

const CompThumb = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Box
            style={isMobile === "true" ? {} : { position: "sticky", top: 60, right: 0 }}
            bgColor={"#f8f8f8"}
            maxWidth={isMobile === "true" ? "100%" : "280px"}
            minWidth={isMobile === "true" ? "100%" : "280px"}
            padding={"32px 35px 36px"}
            margin={isMobile === "true" ? "0 0 40px" : "0 0 134px"}
            alignitems={"flex-start"}
            flexDirection={"column"}
        >
            <Image filename={data.image} alt="logo" style={{ width: data.size.width, height: data.size.height }} />
            <Box margin={"30px 0 0"} alignitems={"flex-start"} gap={"30px"} flexDirection={"column"}>
                {data.items.map((item, index) => {
                    return (
                        <Wrapper key={index}>
                            <TextBold margin={"0 0 8px"} type={"16"}>
                                {t(item.title)}
                            </TextBold>
                            {item.desc.map((item_d, index) => {
                                return (
                                    <TextNormal key={index} padding={"0"} type={"16"} style={{ wordBreak: "keep-all" }}>
                                        {t(item_d)}
                                    </TextNormal>
                                );
                            })}
                        </Wrapper>
                    );
                })}
            </Box>
        </Box>
    );
};

export default CompThumb;
