import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Container, Box, BackgroundImageWrapper } from "components/style/layoutStyle";
import { TextNormal, TextBold } from "components/style/textStyle";
import Image from "components/image/image";
import { useTranslation } from "react-i18next";

const TitleBanner = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();
    const height = isMobile ? "180px" : "280px";
    return (
        <Container height={height}>
            <BackgroundImageWrapper maxWidth={"100%"}>
                <Image filename={isMobile === "true" ? data.image_mobile : data.image} alt={data.title} style={{ height: height }} />
            </BackgroundImageWrapper>
            <Box alignitems={isMobile === "true" ? "center" : "flex-start"} justifycontent={"center"} padding={"0 20px"} flexDirection={"column"}>
                <TextNormal textAlign={isMobile === "true" ? "center" : "left"} color={"#fff"} padding={isMobile === "true" ? "0 0 2px" : "0 0 10px"} type={isMobile === "true" ? "16" : "20"}>
                    고객 인터뷰
                </TextNormal>
                <TextBold textAlign={isMobile === "true" ? "center" : "left"} color={"#fff"} type={isMobile === "true" ? "30" : "46"}>
                    {t(data.company)}
                </TextBold>
            </Box>
        </Container>
    );
};

export default TitleBanner;
