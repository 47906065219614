import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import CompThumb from "./compThumb";
import InterViewDesc from "./interviewDesc";
import TitleBanner from "./titleBanner";
import OtherInterviewCard from "./otherInterviewCard";
import { Container, Box } from "components/style/layoutStyle";

const Interview = ({ interview, thumb }) => {
    const { isMobile } = ResponsiveMedia();
    return (
        <>
            <TitleBanner data={interview} />
            <Container padding={isMobile === "true" ? "40px 0 0px" : "100px 20px 20px"}>
                <Box flexDirection={"column"}>
                    <Box padding={"0 20px"} alignitems={isMobile === "true" ? "center" : "flex-start"} flexDirection={isMobile === "true" ? "column" : "row-reverse"}>
                        <CompThumb data={thumb} />
                        <InterViewDesc data={interview} />
                    </Box>
                    <OtherInterviewCard data={interview.other} />
                </Box>
            </Container>
        </>
    );
};

export default Interview;
