import styled from "styled-components";

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ddd;
`;

export const PrevButtonText = styled.div`
    font-size: ${(props) => props.fontSize};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.6px;
    text-align: left;
    color: #1e1e1e;
`;
